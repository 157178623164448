<template>
  <div class="app-box">
    <x-screen-tab :bindYear="selectObject.year" :bindMonth="selectObject.month||0" @change="screenChange"></x-screen-tab>
    <x-scroll-view :top="0.5">
      <table v-if="xhrData&&xhrData.items.length>0" class="data-table double-table" style="width:5.4rem;">
        <colgroup>
          <col style="width:1.6rem;">
          <col style="width:1.6rem;">
          <col style="width:0.8rem;">
          <col style="width:0.6rem;">
          <col style="width:0.8rem;">
          <!-- <col style="width:0.6rem;"> -->
        </colgroup>
        <thead>
          <tr>
            <th>
              <span>发货方</span>
            </th>
            <th scope="col">
              <span>收货方</span>
            </th>
            <th scope="col">
              <span>类型</span>
            </th>
            <th scope="col">
              <span>业务员</span>
            </th>
            <th scope="col">
              <span class="table-sort-icon" :class="[sort?'table-sort-down-icon':'table-sort-up-icon']" @click="sortEvent">数量</span>
            </th>
            <!-- <th scope="col">
              <span>客户ID</span>
            </th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in xhrData.items" :key="index">
            <th scope="row">{{item.shipperName}}</th>
            <td>{{item.receiveName}}</td>
            <td>{{item.receiveCompanyType}}</td>
            <td>{{item.sellerName}}</td>
            <td>{{item.count}}</td>
            <!-- <td>{{item.receiveId}}</td> -->
          </tr>
        </tbody>
      </table>
      <x-no-data-bg v-if="ajaxEnd&&xhrData&&xhrData.items.length<=0"></x-no-data-bg>
    </x-scroll-view>
  </div>
</template>

<script>
import scrollView from "@/components/scrollView";
import screenTab from "@/components/screenTab";
import { countDetail } from "@/api/reportCenter";
import noDataBg from "@/components/noDataBg";

export default {
  data () {
    return {
      sort: true,
      xhrData: null,
      ajaxEnd: false,
      selectObject: {
        level2Item: this.$route.params.towItem,
        medicineValue: this.$route.params.medicineValue,
        year: this.$route.params.year,
        month: parseInt(this.$route.params.month) || null,
        queryTypeValue: this.$route.params.typeValue
      }
    };
  },
  created () {
    document.title = `${this.$route.params.medicine}${this.selectObject.level2Item}`;
    this.getDataList();
  },
  methods: {
    sortEvent () {
      this.sort = !this.sort;
      this.xhrData.items.reverse();
    },
    getDataList () {
      countDetail(this.selectObject).then(xhr => {
        this.xhrData = xhr.data;
        this.sort = true;
        this.ajaxEnd = true;
      });
    },
    screenChange (data) {
      this.selectObject.year = data.year.code;
      this.selectObject.month = data.month.code || null;
      this.getDataList();
    }
  },
  watch: {
    $route (to, from) {
      if (to.meta.index <= from.meta.index) {
        document.title = `${this.$route.params.medicine}${this.selectObject.level2Item}`;
      }
    }
  },
  components: {
    [scrollView.name]: scrollView,
    [screenTab.name]: screenTab,
    [noDataBg.name]: noDataBg
  }
};
</script>

<style scoped>
</style>
